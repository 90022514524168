import React from 'react';
import { SvgAmenitiesList } from '../svg/amenities';
import SvgAmenitiesDefault from 'src/assets/svg/amenities/default.svg';
import './index.scss';

export default function SpotAmenitiesIcon({ amenities, addedClass, onlyIcon = false }) {
    const AmenitiesIcon = SvgAmenitiesList[amenities.component] || SvgAmenitiesDefault;
    const ameName = amenities.component == 'Indoor' ? 'Indoor play space' : amenities.name || amenities.amenity;

    if (!AmenitiesIcon) {
        return '';
    }

    return (
        <p className={addedClass}>
            <span className="spot-amenities-icon">
                <AmenitiesIcon />
            </span>
            {!onlyIcon && ameName}
        </p>
    );
}
