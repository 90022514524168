import bemCn from 'bem-cn';
import React from 'react';
import SniffButton from '../sniff-button';
import SvgLocation from 'src/assets/svg/SvgLocation.svg';
import './empty-spots.scss';

const b = bemCn('empty-spots');

const EmptySpots = ({ noItems = true, addNewSpotFilter }) => {
    return (
        <div className={b()}>
            {noItems && (
                <div className={b('location')}>
                    <SvgLocation width={80} height={80} />
                </div>
            )}
            <div className={b('head').mix('snif-m2 snif-semibold')}>Not seeing spots you're looking for?</div>
            {noItems && (
                <div className={b('message').mix('snif-p snif-regular grey')}>
                    Don't worry we can notify you when new spots are added that match this search
                </div>
            )}
            <SniffButton className={b('button')} color={noItems ? 'primary' : 'secondary'} onClick={addNewSpotFilter}>
                Manage new spot alerts
            </SniffButton>
        </div>
    );
};

export default EmptySpots;
