import React, { useEffect } from 'react';
import { Map, MapkitProvider, useMap } from 'react-mapkit';
import usePrevious from '../../services/hooks/usePrevious';
import './style.scss';

const CIRCLE_RADIUS = 322; // 0.2 miles in meters

const RenderMap = ({ center, cameraDistance, circle, setLatLong, anno, draggable = false }) => {
    const prevCenter = usePrevious(center);

    const { map, mapProps, mapkit } = useMap({
        center: [center.latitude, center.longitude],
        showsCompass: 'hidden',
        cameraDistance,
    });

    useEffect(() => {
        if (map && mapkit && center?.longitude && center?.latitude) {
            if (prevCenter.latitude !== center.latitude || prevCenter.longitude !== center.longitude) {
                map.setCenterAnimated(new mapkit.Coordinate(center.latitude, center.longitude), false);

                if (anno) {
                    map.removeAnnotations(map.annotations);
                    const coordinate = new mapkit.Coordinate(center.latitude, center.longitude);
                    const marker = new mapkit.MarkerAnnotation(coordinate, {
                        draggable: true,
                        selected: true,
                        color: '#3aa648',
                    });

                    if (draggable) {
                        marker.addEventListener('drag-end', function () {
                            map.setCenterAnimated(marker.coordinate);
                            setLatLong(marker.coordinate);
                        });
                    }

                    map.addAnnotation(marker);
                    map.setCameraDistanceAnimated(1800);
                }
            }
        }
    }, [map, mapkit, prevCenter, center]);

    useEffect(() => {
        if (map && mapkit) {
            if (circle) {
                const coordinate = new mapkit.Coordinate(center.latitude, center.longitude);
                const circleOverlay = new mapkit.CircleOverlay(coordinate, CIRCLE_RADIUS);
                circleOverlay.style = new mapkit.Style({
                    fillColor: '#50B820',
                    fillOpacity: 0.3,
                    strokeColor: '#50B820',
                    lineWidth: 2,
                });

                map.addOverlay(circleOverlay);
            } else if (anno) {
                const coordinate = new mapkit.Coordinate(center.latitude, center.longitude);
                const marker = new mapkit.MarkerAnnotation(coordinate, {
                    draggable: true,
                    selected: true,
                    color: '#3aa648',
                });

                if (draggable) {
                    marker.addEventListener('drag-end', function () {
                        map.setCenterAnimated(marker.coordinate);
                        setLatLong(marker.coordinate);
                    });
                }

                map.addAnnotation(marker);
                map.setCameraDistanceAnimated(1800);
            }
        }
    }, [map, mapkit, circle, anno, draggable]);

    return <Map {...mapProps} />;
};

const SniffAppleMaps = (props) => {
    if (!props.mapkitToken) {
        return '';
    }

    return (
        <MapkitProvider tokenOrCallback={props.mapkitToken} showsZoomControl>
            <RenderMap {...props} />
        </MapkitProvider>
    );
};

export default SniffAppleMaps;
