export const GENERAL_SNIFFSPOT_RULES = [
    {
        type: 'success',
        text: 'Dogs must be leashed when entering and exiting the spot.',
    },
    {
        type: 'success',
        text: 'Make sure the area is safe and secure before bringing your dog in.',
    },
    {
        type: 'success',
        text: 'Never enter a spot before your booking start time and never leave late.',
    },
    {
        type: 'success',
        text: 'Pick up after your dogs. Leave the spot as it was when you arrived.',
    },
    {
        type: 'success',
        text: 'Always review and abide by spot specific rules.',
    },
    {
        type: 'success',
        text: 'Unless otherwise stated, restrooms are not available on site.',
    },
];
