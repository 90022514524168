export const getCountry = () => {
    const timezones = {
        'America/Adak': 1,
        'America/Anchorage': 1,
        'America/Boise': 1,
        'America/Chicago': 1,
        'America/Denver': 1,
        'America/Detroit': 1,
        'America/Indiana/Indianapolis': 1,
        'America/Indiana/Knox': 1,
        'America/Indiana/Marengo': 1,
        'America/Indiana/Petersburg': 1,
        'America/Indiana/Tell_City': 1,
        'America/Indiana/Vevay': 1,
        'America/Indiana/Vincennes': 1,
        'America/Indiana/Winamac': 1,
        'America/Juneau': 1,
        'America/Kentucky/Louisville': 1,
        'America/Kentucky/Monticello': 1,
        'America/Los_Angeles': 1,
        'America/Menominee': 1,
        'America/Metlakatla': 1,
        'America/New_York': 1,
        'America/Nome': 1,
        'America/North_Dakota/Beulah': 1,
        'America/North_Dakota/Center': 1,
        'America/North_Dakota/New_Salem': 1,
        'America/Phoenix': 1,
        'America/Sitka': 1,
        'America/Yakutat': 1,
        'Pacific/Honolulu': 1,
        'US/Arizona': 1,
        'US/Hawaii': 1,
    };

    if (typeof window === 'undefined') {
        return null;
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (timezone === '' || !timezone) {
        return null;
    }

    const country = timezones[timezone];
    return country;
};

export const COUNTRY_ISO_CODE = {
    Australia: 'AU',
    Austria: 'AT',
    Belgium: 'BE',
    Bulgaria: 'BG',
    Canada: 'CA',
    China: 'CN',
    'Czech Republic': 'CZ',
    Denmark: 'DK',
    Finland: 'FI',
    France: 'FR',
    Georgia: 'GE',
    Germany: 'DE',
    Greece: 'GR',
    Greenland: 'GL',
    'Hong Kong': 'HK',
    Hungary: 'HU',
    Iceland: 'IS',
    Indonesia: 'ID',
    Ireland: 'IE',
    Israel: 'IL',
    Italy: 'IT',
    Japan: 'JP',
    Malaysia: 'MY',
    Mexico: 'MX',
    Monaco: 'MC',
    Netherlands: 'NL',
    'New Zealand': 'NZ',
    Norway: 'NO',
    Poland: 'PL',
    Portugal: 'PT',
    Singapore: 'SG',
    'South Korea': 'KR',
    Spain: 'ES',
    Sweden: 'SE',
    Switzerland: 'CH',
    Taiwan: 'TW',
    Thailand: 'TH',
    'U.S. Virgin Islands': 'VI',
    'United Kingdom': 'GB',
    'United States': 'US',
    USA: 'US',
    Vietnam: 'VN',
};
