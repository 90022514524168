// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyAaLCqfgJam5xganH4Mjpp3f1O8Z8vJw1k',
    authDomain: 'sniffspot-1536749561931.firebaseapp.com',
    databaseURL: 'https://sniffspot-1536749561931.firebaseio.com',
    projectId: 'sniffspot-1536749561931',
    storageBucket: 'sniffspot-1536749561931.appspot.com',
    messagingSenderId: '615270541288',
    appId: '1:615270541288:web:97cb125114184f97cfa65d',
    measurementId: 'G-G6KD383WXF',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(app);

// Initialize Performance Monitoring and get a reference to the service
export const perf = getPerformance(app);
