import React from 'react';
import { RESERVATION_TYPE } from '../../consts/reservation.consts';
import SniffButton from '../sniff-button';
import './index.scss';

const ListingAvailability = ({ isMobile, handleBook }) => {
    return (
        <div className={`availability ${isMobile ? 'container' : ''}`}>
            <h2 className="snif-m1 snif-semibold mb-3 mt-3">Availability</h2>
            <SniffButton
                className="mb-2"
                color="secondary"
                size="lg"
                onClick={() => handleBook(RESERVATION_TYPE.CALENDAR)}
            >
                Find an available time
            </SniffButton>
            <hr />
        </div>
    );
};

export default ListingAvailability;
