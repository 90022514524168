import React, { useEffect, useState } from 'react';
import { getTestWithProbSSR } from '../../helpers/ssr';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { USER_TEST_GROUP } from '../../consts/profile.const';
import { RouteFormatter } from '../../routes';
import { isUnverified } from '../../consts/user.consts';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import { AUTH_TESTS } from '../../pages/spot-view-page/auth-test.const';
import { showPrice } from '../../helpers/price';
import MobileSubPage from '../mobile-sub-page';
import ModalMobile from '../modal-mobile';
import SniffButton from '../sniff-button';
import SvgCircleCheck from 'src/assets/svg/SvgCircleCheck.svg';
import './index.scss';

const CookieService = require('../../services/cookie.service');
const useGetProbabilityTest = require('../../services/hooks/useGetProbabilityTest');

const ExtrasList = ({ extras, isForeign }) => (
    <div className="extras-list">
        {extras.map((e, i) => {
            return (
                <div key={i}>
                    <div className="extras-item">
                        <SvgCircleCheck className="extras-icon" />
                        <div className="item-right">
                            <h4 className="extra-item-title snif-p snif-semibold">
                                <span>{e.title}</span>
                                <span className="text-primary">{showPrice(e.price, 0, isForeign)}</span>
                            </h4>
                            <p className="extra-item-desc snif-p text-pre">{e.description}</p>
                        </div>
                    </div>
                    {extras.length - 1 !== i && <hr className="mb-3" />}
                </div>
            );
        })}
    </div>
);

const MobileExtrasModal = ({ showModal, toggleModal, useStateSSR, useEffectSSR, children }) => (
    <MobileSubPage title="Extras" show={showModal} onBack={toggleModal} mBottom={96} {...{ useStateSSR, useEffectSSR }}>
        {children}
    </MobileSubPage>
);

const DesktopExtrasModal = ({ showModal, toggleModal, children }) => (
    <ModalMobile title="Extras" isOpen={showModal} toggle={toggleModal}>
        {children}
    </ModalMobile>
);

export default function ListingExtras({ spot, extras = [], serverSSR, useStateSSR, useEffectSSR, userDetails, mutateViewAuthSpot }) {
    const isMobile = useMobileScreen();
    const { getProbTest } = serverSSR ? {} : useGetProbabilityTest.default();
    const [showModal, setShowModal] = useState(false);
    const [ExtrasModal, setExtrasModal] = useState(() => MobileExtrasModal);
    const isForeign = spot?.country !== 'United States';

    const toggleModal = async () => {
        const newShow = !showModal;
        const isUnver = isUnverified(userDetails);

        // guest auth test
        if (!userDetails?.id || isUnver) {
            const re = `${window.location.pathname}#${AUTH_TESTS.ALL_EXTRAS}`;

            if (isUnver) {
                CookieService.default.set(COOKIE_PARAM_NAME.REDIRECT, window.location.href);
                CookieService.default.set(COOKIE_PARAM_NAME.REDIRECT_ONBOARD, re);
                window.location = RouteFormatter.finishYourAccount();
                return;
            }

            const isAuthTest = await getTestWithProbSSR({
                cookie: COOKIE_PARAM_NAME.GUEST_AUTH_TEST,
                getProbTest,
                userTestGroup: USER_TEST_GROUP.GUEST_AUTH_TEST,
            });

            if (isAuthTest != null) {
                mutateViewAuthSpot && mutateViewAuthSpot({ variables: { testGroup: isAuthTest } });
                if (isAuthTest) {
                    CookieService.default.set(COOKIE_PARAM_NAME.REDIRECT, re);
                    window.location = RouteFormatter.signUp();
                    return;
                }
            }
        }

        setShowModal(newShow);
    };

    const ExtrasModalComp = (
        <ExtrasModal {...{ showModal, toggleModal, useStateSSR, useEffectSSR }}>
            <ExtrasList extras={extras} isForeign={isForeign} />
        </ExtrasModal>
    );

    useEffect(() => {
        if (!isMobile) {
            setExtrasModal(() => DesktopExtrasModal);
        }

        // guest auth test
        const urlHash = window.location.hash.substring(1);
        if (userDetails?.id && urlHash == AUTH_TESTS.ALL_EXTRAS) {
            setShowModal(true);
        }
    }, []);

    if (extras.length <= 0) {
        return '';
    }

    return (
        <div className="px-2 px-md-0">
            <h2 className="snif-m1 snif-semibold mb-3 mt-3">Extras</h2>
            <div className="extras-list">
                {extras.slice(0, 3).map((e, i) => {
                    return (
                        <div className="extras-item" key={i}>
                            <SvgCircleCheck className="extras-icon" />
                            <div className="item-right">
                                <h4 className="extra-item-title snif-p snif-semibold">
                                    <span>{e.title}</span>
                                    <span className="text-primary">{showPrice(e.price, 0, isForeign)}</span>
                                </h4>
                                <p className="extra-item-desc snif-p text-pre">{e.description}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
            {extras.length > 3 && (
                <>
                    <SniffButton color="secondary" size="lg" className="spot-extras-button" onClick={toggleModal}>
                        Show all {extras.length} extras
                    </SniffButton>
                    {ExtrasModalComp}
                </>
            )}
            <hr className="mt-0" />
        </div>
    );
}
