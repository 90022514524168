import React from 'react';
import { showSize } from '../../helpers/common';
import { getInitState } from '../../helpers/spot-slug';
import { PREVIEW_TYPES } from '../../consts/images.const';
import { RouteFormatter } from '../../routes';
import { showDistanceFromYou } from '../../helpers/spot';
import ExpandedSpotDetail from './expanded-spot-detail';
import RequestMorePhotos from '../request-more-photos';
import SeoLocalBusiness from '../seo-local-business';
import SpotTopBadge from '../spot-top-badge';
import CarouselSSR from '../carousel/carousel-ssr';
import SvgFenced from 'src/assets/svg/SvgFenced.svg';
import SvgPin from 'src/assets/svg/SvgPin.svg';
import SvgRuler from 'src/assets/svg/SvgRuler.svg';
import SvgStarSolid from 'src/assets/svg/SvgStarSolid.svg';
import SvgSubscription from 'src/assets/svg/SvgSubscription.svg';
import './index.scss';
import './item-ssr.scss';

const emptyCarouselStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    overflow: 'hidden',
};

const SPOT_ENCLOSURE = {
    FULLY_FENCED: 'Fully Fenced',
    PARTIALLY_FENCED: 'Partially Fenced',
    UNFENCED: 'Unfenced',
};

const showPrice = (price) => {
    const isFromForeign = false; //  myCountry !== 1 || foreign;
    return `${parseFloat(price).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
    })}${isFromForeign ? ' USD' : ''}`;
};

const ItemSSR = ({
    item,
    item: { city, state, distanceFrom, spotUrl },
    isMobile,
    type,
    listingPage,
    reservationStyle,
    homePage,
    withBookBtn = false,
    target = '_self',
    showDesc,
    spotMembership = false,
    isListingTest,
    useState,
    useEffect,
    firstEager,
    disableSwipe,
    noRich = false,
    contentTest,
}) => {
    const initState = state ? getInitState(state) : '';
    const fixedRating = Math.round(item.rating * 10) / 10;
    const isExpandTest = contentTest?.details;
    const locationText = state || city ? `${city ? `${city},` : ''}${state ? ` ${initState || state}${showDistanceFromYou(distanceFrom)}` : ''}` : '';

    const chooseCssClass = () => {
        switch (type) {
            case PREVIEW_TYPES.MAP: {
                return 'map-preview';
            }
            case PREVIEW_TYPES.PREVIEW: {
                return 'listing-preview';
            }
        }
    };

    return (
        <div className={`${chooseCssClass()} ${homePage ? 'home-page' : ''} ${isListingTest ? 'listing-test mb-2 b-bot' : 'mb-2'}`}>
            {!noRich && <SeoLocalBusiness spot={item} />}
            <a className="link-spot" href={RouteFormatter.listings({ first: spotUrl })} target={target}>
                <div className="carousel">
                    {item.allPhotos.length > 0 ? (
                        <div className={`carousel__spot-photo-container ${firstEager ? 'p-lcp' : ''}`}>
                            <CarouselSSR
                                {...{
                                    item,
                                    type,
                                    useState,
                                    useEffect,
                                    disableSwipe,
                                    lazy: firstEager ? 'eager' : 'lazy',
                                }}
                                spotGallery
                            />
                        </div>
                    ) : (
                        <div className="carousel__spot-photo-container">
                            <div style={emptyCarouselStyle}>
                                <RequestMorePhotos id={item.id} />
                            </div>
                        </div>
                    )}
                    <SpotTopBadge spot={item} />
                </div>
                {isExpandTest ? (
                    <ExpandedSpotDetail {...{ item }} />
                ) : (
                    <div className={`spot-details ${isMobile && !withBookBtn ? 'mb-0' : ''} ${spotMembership ? 'spot-membership' : ''}`}>
                        <div
                            className={`item-title snif-s1 ${listingPage ? 'snif-medium' : 'snif-regular'} ${listingPage ? 'listing-page' : ''} ${
                                item.offerSniffpass ? 'offer-membership' : ''
                            }`}
                        >
                            <span>{item.title}</span>
                            {listingPage && item.offerSniffpass && <SvgSubscription />}
                        </div>
                        {showDesc && item.description && <p className="item-title-sub mt-1 mb-1">{item.description}</p>}
                        {spotMembership ? (
                            <p className="snif-s1 snif-regular text-deep mt-1">
                                Starting at
                                <span className="snif-p snif-semibold text-dark">{` $${item.memberPrice2Hour} `}</span>
                                dog / month
                            </p>
                        ) : (
                            <>
                                <div className="item-detail d-flex justify-content-between">
                                    {!!item.enclosureType && (
                                        <div className="d-flex mt-1">
                                            {!!item.enclosureType && (
                                                <div className="spot-enc-tag me-05">
                                                    <SvgFenced />
                                                    <span className="snif-s2 snif-s1-pc snif-regular">
                                                        {SPOT_ENCLOSURE[item.enclosureType.toUpperCase()]}
                                                    </span>
                                                </div>
                                            )}
                                            {!!item.size && (
                                                <div className="spot-size-tag">
                                                    <SvgRuler />
                                                    <span className="snif-s2 snif-s1-pc snif-regular">{showSize(item.size, item.sizeUnits)}</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {!!item.rating && (
                                        <div className="spot-rating-tag mt-1">
                                            <span className="star-rating">
                                                <SvgStarSolid />
                                            </span>
                                            <span className="snif-s2 snif-s1-pc snif-medium ms-05 me-05">{fixedRating}</span>
                                            <span className="snif-s2 snif-s1-pc text-medium">({item.reviewsCount})</span>
                                        </div>
                                    )}
                                </div>
                                <div className="item-detail-price">
                                    {!reservationStyle && item.price ? (
                                        <div className="item-detail-price-left">
                                            <span className="snif-p snif-semibold">{showPrice(item.price)}</span>
                                            <span className="snif-s1 text-deep"> dog / hour</span>
                                        </div>
                                    ) : (
                                        <div className="item-detail-price-left" />
                                    )}
                                    {locationText && (
                                        <div className="item-detail-price-right">
                                            <div className="d-flex align-items-center">
                                                <SvgPin width={16} height={16} />
                                            </div>
                                            <span className="snif-s2 snif-xs-pc snif-medium ms-1">{locationText}</span>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </a>
            {listingPage && <div className="mb-2 mb-md-3" />}
        </div>
    );
};

export default ItemSSR;
