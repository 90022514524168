import React from 'react';
import moment from 'moment-timezone';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import { ImageHelper } from '../../classes/image-helper.class';
import { RouteFormatter } from '../../routes';
import AccountVerifiedBanner from '../account-verified-banner';
import PictureSet from '../picture-set';
import './index.scss';

const ListingHost = ({ hostId, nameAndInitial, avatar, info, joinDate, acceptanceRate, responseTime, useStateSSR, serverSSR }) => {
    const hostLink = RouteFormatter.profile({ id: hostId });
    const avatarUrl = avatar ? ImageHelper.getUrlFromOptions(avatar, `fit-in/${IMG_RESIZES.x0256}`) : '';

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.location.href = hostLink;
    };

    if (!nameAndInitial) {
        return '';
    }

    return (
        <section id="host">
            <div className="host px-2 px-md-0">
                <h2 className="snif-m2 snif-medium mt-4">Meet your host</h2>
                <div className="row host-info h4">
                    <div className="col-md-12">
                        <div className="d-flex text-dark text-deco-none media" onClick={handleClick}>
                            <a href={hostLink} rel="nofollow">
                                <PictureSet
                                    imgs={avatarUrl}
                                    width={72}
                                    height={72}
                                    className="rounded-circle host-avatar"
                                    useState={useStateSSR}
                                    SSR={!!useStateSSR}
                                    isAvatar
                                />
                            </a>
                            <div className="media-body">
                                <div className="host-name snif-m1">{nameAndInitial}</div>
                                {!!acceptanceRate && <div className={'host-name'}>{`Acceptance rate: ${acceptanceRate}%`}</div>}
                                {!!responseTime && <div className={'host-name'}>{`Typical response time: ${responseTime}`}</div>}
                                <p className="host-join-date snif-s1 mb-0">Joined in {moment(joinDate).format('MMMM YYYY')}</p>
                            </div>
                        </div>
                        <AccountVerifiedBanner addedClass="mt-3 mb-3" userId={hostId} serverSSR={serverSSR} />
                    </div>
                </div>
                <p className="snif-p mt-2 mb-0 host-info-about text-pre">{info}</p>
            </div>
        </section>
    );
};

export default ListingHost;
