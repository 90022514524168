import loadable from '@loadable/component';

const DefaultSvgA = loadable(() => import('../../assets/svg/amenities/default.svg'));
const ChairsSvgA = loadable(() => import('../../assets/svg/amenities/chairs.svg'));
const DogBagsSvgA = loadable(() => import('../../assets/svg/amenities/dog-bags.svg'));
const DogDrinkingWaterSvgA = loadable(() => import('../../assets/svg/amenities/dog-drinking-water.svg'));
const HandSanitizerSvgA = loadable(() => import('../../assets/svg/amenities/hand-sanitizer.svg'));
const SignSvgA = loadable(() => import('../../assets/svg/amenities/sign.svg'));
const TrashCanSvgA = loadable(() => import('../../assets/svg/amenities/trash-can.svg'));
const AgilityEquipmentSvgA = loadable(() => import('../../assets/svg/amenities/agility-equipment.svg'));
const DiggingAreaSvgA = loadable(() => import('../../assets/svg/amenities/digging-area.svg'));
const DogFirstAidSvgA = loadable(() => import('../../assets/svg/amenities/dog-first-aid.svg'));
const DogTowelsSvgA = loadable(() => import('../../assets/svg/amenities/dog-towels.svg'));
const DogToysSvgA = loadable(() => import('../../assets/svg/amenities/dog-toys.svg'));
const DogTreatsSvgA = loadable(() => import('../../assets/svg/amenities/dog-treats.svg'));
const DockForDogDivingA = loadable(() => import('../../assets/svg/amenities/dock-diving.svg'));
const DoggySwimmingVestSvgA = loadable(() => import('../../assets/svg/amenities/doggy-swimming-vest.svg'));
const FertilizerFreeSvgA = loadable(() => import('../../assets/svg/amenities/fertilizer-free.svg'));
const ParkourObstaclesSvgA = loadable(() => import('../../assets/svg/amenities/parkour-obstacles.svg'));
const PesticideFreeSvgA = loadable(() => import('../../assets/svg/amenities/pesticide-free.svg'));
const SmallDogSvgA = loadable(() => import('../../assets/svg/amenities/small-dog.svg'));
const SwimmingPoolSvgA = loadable(() => import('../../assets/svg/amenities/swimming-pool.svg'));
const WaterHoseSvgA = loadable(() => import('../../assets/svg/amenities/water-hose.svg'));
const KiddiePoolSvgA = loadable(() => import('../../assets/svg/amenities/kiddie-pool.svg'));
const LureCourseA = loadable(() => import('../../assets/svg/amenities/lure-course.svg'));
const WaterMisterSvgA = loadable(() => import('../../assets/svg/amenities/water-mister.svg'));
const WaterSprinklerSvgA = loadable(() => import('../../assets/svg/amenities/water-sprinkler.svg'));
const AccessibleSvgA = loadable(() => import('../../assets/svg/amenities/accessible.svg'));
const BugSpraySvgA = loadable(() => import('../../assets/svg/amenities/bug-spray.svg'));
const ToysSvgA = loadable(() => import('../../assets/svg/amenities/toys.svg'));
const DrinksSvgA = loadable(() => import('../../assets/svg/amenities/drinks.svg'));
const ElectricOutletSvgA = loadable(() => import('../../assets/svg/amenities/electric-outlet.svg'));
const FirePitSvgA = loadable(() => import('../../assets/svg/amenities/fire-pit.svg'));
const HammocksSvgA = loadable(() => import('../../assets/svg/amenities/hammocks.svg'));
const IndoorRestroomSvgA = loadable(() => import('../../assets/svg/amenities/indoor-restroom.svg'));
const LitAtNightSvgA = loadable(() => import('../../assets/svg/amenities/lit-at-night.svg'));
const ParkingSvgA = loadable(() => import('../../assets/svg/amenities/parking.svg'));
const OuthouseSvgA = loadable(() => import('../../assets/svg/amenities/outhouse.svg'));
const PooperScooperSvgA = loadable(() => import('../../assets/svg/amenities/pooper-scooper.svg'));
const RainShelterSvgA = loadable(() => import('../../assets/svg/amenities/rain-shelter.svg'));
const SnacksSvgA = loadable(() => import('../../assets/svg/amenities/snacks.svg'));
const SunscreenSvgA = loadable(() => import('../../assets/svg/amenities/sunscreen.svg'));
const SwingSvgA = loadable(() => import('../../assets/svg/amenities/swing.svg'));
const TableSvgA = loadable(() => import('../../assets/svg/amenities/table.svg'));
const WifiSvgA = loadable(() => import('../../assets/svg/amenities/wifi.svg'));
const BeachSvgA = loadable(() => import('../../assets/svg/amenities/beach.svg'));
const FarmAnimalSvgA = loadable(() => import('../../assets/svg/amenities/farm-animal.svg'));
const FieldSvgA = loadable(() => import('../../assets/svg/amenities/field.svg'));
const FlowerGardenSvgA = loadable(() => import('../../assets/svg/amenities/flower-garden.svg'));
const FruitPickingSvgA = loadable(() => import('../../assets/svg/amenities/fruit-picking.svg'));
const HillSvgA = loadable(() => import('../../assets/svg/amenities/hill.svg'));
const IndoorSvgA = loadable(() => import('../../assets/svg/amenities/indoor.svg'));
const LakePondSvgA = loadable(() => import('../../assets/svg/amenities/lake-pond.svg'));
const LongGrassSvgA = loadable(() => import('../../assets/svg/amenities/long-grass.svg'));
const MudSvgA = loadable(() => import('../../assets/svg/amenities/mud.svg'));
const MulchSvgA = loadable(() => import('../../assets/svg/amenities/mulch.svg'));
const PastureSvgA = loadable(() => import('../../assets/svg/amenities/pasture.svg'));
const RiverStreamCreekSvgA = loadable(() => import('../../assets/svg/amenities/river-stream-creek.svg'));
const RubberFloorSvgA = loadable(() => import('../../assets/svg/amenities/rubber-floor.svg'));
const SandSvgA = loadable(() => import('../../assets/svg/amenities/sand.svg'));
const ScenicViewSvgA = loadable(() => import('../../assets/svg/amenities/scenic-view.svg'));
const ShortGrassSvgA = loadable(() => import('../../assets/svg/amenities/short-grass.svg'));
const SnowSvgA = loadable(() => import('../../assets/svg/amenities/snow.svg'));
const TrailSvgA = loadable(() => import('../../assets/svg/amenities/trail.svg'));
const TurfSvgA = loadable(() => import('../../assets/svg/amenities/turf.svg'));
const WetlandsSvgA = loadable(() => import('../../assets/svg/amenities/wetlands.svg'));
const WildAnimalSvgA = loadable(() => import('../../assets/svg/amenities/wild-animal.svg'));
const WoodChipsSvgA = loadable(() => import('../../assets/svg/amenities/wood-chips.svg'));
const WoodsSvgA = loadable(() => import('../../assets/svg/amenities/woods.svg'));

export const SvgAmenitiesDefault = DefaultSvgA;
export const SvgAmenitiesChairs = ChairsSvgA;
export const SvgAmenitiesDogBags = DogBagsSvgA;
export const SvgAmenitiesDogDrinkingWater = DogDrinkingWaterSvgA;
export const SvgAmenitiesHandSanitizer = HandSanitizerSvgA;
export const SvgAmenitiesSign = SignSvgA;
export const SvgAmenitiesTrashCan = TrashCanSvgA;
export const SvgAmenitiesAgilityEquipment = AgilityEquipmentSvgA;
export const SvgAmenitiesDiggingArea = DiggingAreaSvgA;
export const SvgAmenitiesDogFirstAid = DogFirstAidSvgA;
export const SvgAmenitiesDogTowels = DogTowelsSvgA;
export const SvgAmenitiesDogToys = DogToysSvgA;
export const SvgAmenitiesDogTreats = DogTreatsSvgA;
export const SvgAmenitiesDoggySwimmingVest = DoggySwimmingVestSvgA;
export const SvgAmenitiesFertilizerFree = FertilizerFreeSvgA;
export const SvgAmenitiesParkourObstacles = ParkourObstaclesSvgA;
export const SvgAmenitiesPesticideFree = PesticideFreeSvgA;
export const SvgAmenitiesSmallDog = SmallDogSvgA;
export const SvgAmenitiesSwimmingPool = SwimmingPoolSvgA;
export const SvgAmenitiesWaterHose = WaterHoseSvgA;
export const SvgAmenitiesKiddiePool = KiddiePoolSvgA;
export const SvgAmenitiesWaterMister = WaterMisterSvgA;
export const SvgAmenitiesWaterSprinkler = WaterSprinklerSvgA;
export const SvgAmenitiesAccessible = AccessibleSvgA;
export const SvgAmenitiesBugSpray = BugSpraySvgA;
export const SvgAmenitiesToys = ToysSvgA;
export const SvgAmenitiesDrinks = DrinksSvgA;
export const SvgAmenitiesElectricOutlet = ElectricOutletSvgA;
export const SvgAmenitiesFirePit = FirePitSvgA;
export const SvgAmenitiesHammocks = HammocksSvgA;
export const SvgAmenitiesIndoorRestroom = IndoorRestroomSvgA;
export const SvgAmenitiesLitAtNight = LitAtNightSvgA;
export const SvgAmenitiesParking = ParkingSvgA;
export const SvgAmenitiesOuthouse = OuthouseSvgA;
export const SvgAmenitiesPooperScooper = PooperScooperSvgA;
export const SvgAmenitiesRainShelter = RainShelterSvgA;
export const SvgAmenitiesSnacks = SnacksSvgA;
export const SvgAmenitiesSunscreen = SunscreenSvgA;
export const SvgAmenitiesSwing = SwingSvgA;
export const SvgAmenitiesTable = TableSvgA;
export const SvgAmenitiesWifi = WifiSvgA;
export const SvgAmenitiesBeach = BeachSvgA;
export const SvgAmenitiesFarmAnimal = FarmAnimalSvgA;
export const SvgAmenitiesField = FieldSvgA;
export const SvgAmenitiesFlowerGarden = FlowerGardenSvgA;
export const SvgAmenitiesFruitPicking = FruitPickingSvgA;
export const SvgAmenitiesHill = HillSvgA;
export const SvgAmenitiesIndoor = IndoorSvgA;
export const SvgAmenitiesLakePond = LakePondSvgA;
export const SvgAmenitiesLongGrass = LongGrassSvgA;
export const SvgAmenitiesMud = MudSvgA;
export const SvgAmenitiesMulch = MulchSvgA;
export const SvgAmenitiesPasture = PastureSvgA;
export const SvgAmenitiesRiverStreamCreek = RiverStreamCreekSvgA;
export const SvgAmenitiesRubberFloor = RubberFloorSvgA;
export const SvgAmenitiesSand = SandSvgA;
export const SvgAmenitiesScenicView = ScenicViewSvgA;
export const SvgAmenitiesShortGrass = ShortGrassSvgA;
export const SvgAmenitiesSnow = SnowSvgA;
export const SvgAmenitiesTrail = TrailSvgA;
export const SvgAmenitiesTurf = TurfSvgA;
export const SvgAmenitiesWetlands = WetlandsSvgA;
export const SvgAmenitiesWildAnimal = WildAnimalSvgA;
export const SvgAmenitiesWoodChips = WoodChipsSvgA;
export const SvgAmenitiesWoods = WoodsSvgA;
export const SvgAmenitiesDockForDogDiving = DockForDogDivingA;
export const SvgAmenitiesLureCourse = LureCourseA;

export const SvgAmenitiesList = {
    Chairs: SvgAmenitiesChairs,
    DogBags: SvgAmenitiesDogBags,
    DogDrinkingWater: SvgAmenitiesDogDrinkingWater,
    HandSanitizer: SvgAmenitiesHandSanitizer,
    Sign: SvgAmenitiesSign,
    TrashCan: SvgAmenitiesTrashCan,
    AgilityEquipment: SvgAmenitiesAgilityEquipment,
    DiggingArea: SvgAmenitiesDiggingArea,
    DogFirstAid: SvgAmenitiesDogFirstAid,
    DockForDogDiving: SvgAmenitiesDockForDogDiving,
    DogTowels: SvgAmenitiesDogTowels,
    DogToys: SvgAmenitiesDogToys,
    DogTreats: SvgAmenitiesDogTreats,
    DoggySwimmingVest: SvgAmenitiesDoggySwimmingVest,
    FertilizerFree: SvgAmenitiesFertilizerFree,
    ParkourObstacles: SvgAmenitiesParkourObstacles,
    PesticideFree: SvgAmenitiesPesticideFree,
    SmallDog: SvgAmenitiesSmallDog,
    SwimmingPool: SvgAmenitiesSwimmingPool,
    WaterHose: SvgAmenitiesWaterHose,
    KiddiePool: SvgAmenitiesKiddiePool,
    LureCourse: SvgAmenitiesLureCourse,
    WaterMister: SvgAmenitiesWaterMister,
    WaterSprinkler: SvgAmenitiesWaterSprinkler,
    Accessible: SvgAmenitiesAccessible,
    BugSpray: SvgAmenitiesBugSpray,
    Toys: SvgAmenitiesToys,
    Drinks: SvgAmenitiesDrinks,
    ElectricOutlet: SvgAmenitiesElectricOutlet,
    FirePit: SvgAmenitiesFirePit,
    Hammocks: SvgAmenitiesHammocks,
    IndoorRestroom: SvgAmenitiesIndoorRestroom,
    LitAtNight: SvgAmenitiesLitAtNight,
    Parking: SvgAmenitiesParking,
    Outhouse: SvgAmenitiesOuthouse,
    PooperScooper: SvgAmenitiesPooperScooper,
    RainShelter: SvgAmenitiesRainShelter,
    Snacks: SvgAmenitiesSnacks,
    Sunscreen: SvgAmenitiesSunscreen,
    Swing: SvgAmenitiesSwing,
    Table: SvgAmenitiesTable,
    Wifi: SvgAmenitiesWifi,
    Beach: SvgAmenitiesBeach,
    FarmAnimal: SvgAmenitiesFarmAnimal,
    Field: SvgAmenitiesField,
    FlowerGarden: SvgAmenitiesFlowerGarden,
    FruitPicking: SvgAmenitiesFruitPicking,
    Hill: SvgAmenitiesHill,
    Indoor: SvgAmenitiesIndoor,
    LakePond: SvgAmenitiesLakePond,
    LongGrass: SvgAmenitiesLongGrass,
    Mud: SvgAmenitiesMud,
    Mulch: SvgAmenitiesMulch,
    Pasture: SvgAmenitiesPasture,
    RiverStreamCreek: SvgAmenitiesRiverStreamCreek,
    RubberFloor: SvgAmenitiesRubberFloor,
    Sand: SvgAmenitiesSand,
    ScenicView: SvgAmenitiesScenicView,
    ShortGrass: SvgAmenitiesShortGrass,
    Snow: SvgAmenitiesSnow,
    Trail: SvgAmenitiesTrail,
    Turf: SvgAmenitiesTurf,
    Wetlands: SvgAmenitiesWetlands,
    WildAnimal: SvgAmenitiesWildAnimal,
    WoodChips: SvgAmenitiesWoodChips,
    Woods: SvgAmenitiesWoods,
};
