import React from 'react';
import { SPOT_SRC } from '../../consts/spot.consts';
import { useHistory } from 'react-router-dom';
import { RESERVATION_TYPE } from '../../consts/reservation.consts';
import ModalMobileClient from '../../components/modal-mobile';
import ModalMobileSSR from '../../components/modal-mobile/modal-mobile-ssr';
import SniffButton from '../../components/sniff-button';
import SvgDogPoop from 'src/assets/svg/SvgDogPoop.svg';
import SvgFenceUnsafe from 'src/assets/svg/SvgFenceUnsafe.svg';
import SvgInfo from 'src/assets/svg/SvgInfo.svg';
import SvgPhoto from 'src/assets/svg/SvgPhoto.svg';
import SvgStarWarning from 'src/assets/svg/SvgStarWarning.svg';

const RenderInfoWarning = ({ icon, title, sub }) => (
    <div className="book-warning-info-section">
        {icon}
        <div className="info-section-right">
            <div className="snif-p snif-semibold mb-1">{title}</div>
            <div className="snif-p text-deep">{sub}</div>
        </div>
    </div>
);

export const RenderModalWarning = ({ warning, setWarning, spotRating, onAskQuestion, useState, setShowCalendar }) => {
    const history = useHistory();
    const { isLowRating, isLongInactive, isFencingIssue, isCleanIssue, isPhotosIssue, type } = warning;
    const ModalMobile = useState ? ModalMobileSSR : ModalMobileClient;
    const toggle = () => setWarning({ ...warning, isOpen: !warning.isOpen });
    const handleMessage = () => onAskQuestion({});
    const handleContinue = () => {
        if (type === RESERVATION_TYPE.BOOK) {
            toggle();
            if (warning.SSR) {
                setShowCalendar(SPOT_SRC.WEB_SPOT);
            }
            history.push('#' + SPOT_SRC.WEB_SPOT);
        } else if (type === RESERVATION_TYPE.CALENDAR) {
            toggle();
            if (warning.SSR) {
                setShowCalendar(SPOT_SRC.WEB_CALENDAR);
            }
            history.push('#' + SPOT_SRC.WEB_CALENDAR);
        }
    };

    return (
        <ModalMobile
            isOpen={warning.isOpen}
            toggle={toggle}
            title="Before you book"
            className="before-book-warning-modal"
        >
            {!!isCleanIssue && (
                <RenderInfoWarning
                    icon={<SvgDogPoop />}
                    title="Guests have reported cleanliness issues"
                    sub="We recommend confirming that the spot is ready for visits before booking."
                />
            )}
            {!!isFencingIssue && (
                <RenderInfoWarning
                    icon={<SvgFenceUnsafe />}
                    title="Guests have reported the fencing may not be secure"
                    sub="We recommend looking over the spot details to make sure it works for you and your dog before booking."
                />
            )}
            {!!isPhotosIssue && (
                <RenderInfoWarning
                    icon={<SvgPhoto />}
                    title="This host hasn't added many photos"
                    sub="We recommend messaging the host to ask them to add more photos so you can know more before booking."
                />
            )}
            {!!isLongInactive && (
                <RenderInfoWarning
                    icon={<SvgInfo />}
                    title="This spot hasn't had a visit in a while"
                    sub="We recommend messaging the host to confirm availability before booking."
                />
            )}
            {!!isLowRating && (
                <RenderInfoWarning
                    icon={<SvgStarWarning />}
                    title={`This spot has a low rating (${spotRating})`}
                    sub="We recommend looking over the spot details to make sure it works for you and your dog before booking."
                />
            )}
            <div className="book-warning-action">
                <SniffButton size="lg" onClick={handleMessage}>
                    Message host
                </SniffButton>
                <SniffButton className="mt-2 mt-md-0" color="secondary" size="lg" onClick={handleContinue}>
                    Continue to booking
                </SniffButton>
            </div>
        </ModalMobile>
    );
};
export default RenderModalWarning;
