import React, { useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { ImageHelper } from '../../classes/image-helper.class';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import PictureSet from '../../components/picture-set';

const itemHeight = 316;

const MobilePhotoGallery = ({ singlePhotoHandler, mobilePhotoCount, photos, hostId, title, state, city }) => {
    const altImg = title ? `${title} - ${city}, ${state}` : 'Spot thumbnail';
    const listRef = useRef({});
    const rowHeights = useRef({});

    const getRowHeight = (index) => rowHeights.current[index] + 16 || itemHeight;

    const setRowHeight = (index, size) => {
        listRef.current.resetAfterIndex(0);
        rowHeights.current = { ...rowHeights.current, [index]: size };
    };

    useEffect(() => {
        setTimeout(() => {
            listRef?.current?.scrollToItem(mobilePhotoCount, 'start');
        }, 200);
    }, []);

    return (
        <div className="gallery-photos">
            <AutoSizer>
                {({ width, height }) => (
                    <VariableSizeList
                        ref={listRef}
                        itemSize={getRowHeight}
                        overscanCount={4}
                        width={width}
                        height={height}
                        itemCount={photos.length}
                        style={{ overflow: 'hidden auto' }}
                    >
                        {({ index, style }) => {
                            const rowRef = useRef({});
                            const p = photos[index];
                            let submittedBy = '';

                            if (p.providedById !== null && p.providedBy && hostId !== p.providedBy.id) {
                                const monthYear = moment(p.createdAt).format('MMM YYYY');
                                submittedBy = `Submitted by: ${p.providedBy.firstname} ${p.providedBy.lastInitial}. - ${monthYear}`;
                            }

                            useEffect(() => {
                                if (rowRef.current) {
                                    setRowHeight(index, rowRef.current.clientHeight);
                                }
                            }, [rowRef]);

                            return (
                                <div
                                    key={index}
                                    style={style}
                                    className="photos-box"
                                    onClick={() => singlePhotoHandler(index)}
                                >
                                    <div ref={rowRef}>
                                        <PictureSet
                                            imgs={ImageHelper.getUrlFromOptions(
                                                p.imageAws,
                                                `fit-in/${IMG_RESIZES.x512}`
                                            )}
                                            className="photos-item"
                                            alt={altImg}
                                            width="100%"
                                            height={300}
                                            method="eager"
                                        />
                                        {submittedBy && (
                                            <p className="spot-detail-photos-submitted mb-0">
                                                <span className="photos-text">{submittedBy}</span>
                                            </p>
                                        )}
                                        {p.caption && <p className="spot-detail-photos-caption">{p.caption}</p>}
                                    </div>
                                </div>
                            );
                        }}
                    </VariableSizeList>
                )}
            </AutoSizer>
        </div>
    );
};

export default MobilePhotoGallery;
