export const convertNumToUSD = (num = 0, minimumFractionDigits = 0, maximumFractionDigits = 2) => {
    return parseFloat(num).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits,
        minimumFractionDigits,
    });
};

export const showPrice = (price, minimumFractionDigits) => {
    // const myCountry = getCountry();
    const isFromForeign = false; // myCountry !== 1 || foreign;
    return `${convertNumToUSD(price, minimumFractionDigits)}${isFromForeign ? ' USD' : ''}`;
};
