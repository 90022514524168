import React from 'react';
import { useHistory } from 'react-router';
import { RouteFormatter } from '../../routes';
import SvgMembership from 'src/assets/svg/SvgMembership.svg';
import './render-user-is-member.scss';

const RenderUserIsMember = ({ SSR }) => {
    const history = SSR ? null : useHistory();
    const handleClick = () => {
        if (SSR) {
            window.location = RouteFormatter.subscriptions();
            return;
        }
        history.push(RouteFormatter.subscriptions());
    };

    return (
        <div className="user-member-banner" onClick={handleClick}>
            <SvgMembership />
            <div>
                <p className="snif-s1 snif-p-pc mb-05 mb-md-1">You are a member</p>
                <p className="snif-s1">Thank you for supporting this spot!</p>
            </div>
        </div>
    );
};

export default RenderUserIsMember;
