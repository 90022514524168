import { gql, useLazyQuery, useMutation } from '@apollo/client';

const SPOT_REVIEWS_DATA = gql`
    query spotReviewsData($id: ID!, $sortBy: SortBy, $sortOrder: SortOrder, $first: Int) {
        spot(id: $id) {
            id
            reviews(first: $first, sortBy: $sortBy, sortOrder: $sortOrder) {
                id
                stars
                comment
                createdAt
                submittedAt
                providedBy {
                    id
                    avatarAws
                    activeSniffpass
                    nameAndInitial
                    createdAt
                }
                reply {
                    id
                    text
                    createdAt
                }
                photos {
                    id
                    imageAws
                }
                membershipped
                upvotesCount
                upvote
            }
        }
    }
`;

const CREATE_REPLY = gql`
    mutation createReply($reviewId: ID!, $text: String!) {
        createReply(reviewId: $reviewId, text: $text) {
            id
            text
            createdAt
        }
    }
`;

const REMOVE_REPLY = gql`
    mutation removeReply($id: ID!) {
        removeReply(id: $id) {
            id
        }
    }
`;

const SUBMIT_UPVOTE_REVIEW = gql`
    mutation submitUpvoteReview($id: ID!) {
        upvote(id: $id) {
            id
        }
    }
`;

export function useGetReviews() {
    const [getReviews, { data, loading }] = useLazyQuery(SPOT_REVIEWS_DATA);
    return { getReviews, dataReviews: data?.spot.reviews, loading };
}

export function useAddReply() {
    const [addReply, { loading }] = useMutation(CREATE_REPLY);
    return { addReply, loading };
}

export function useDeleteReply() {
    const [deleteReply, { loading }] = useMutation(REMOVE_REPLY);
    return { deleteReply, loading };
}

export function useHandleUpvote(id) {
    const [upvote, { loading }] = useMutation(SUBMIT_UPVOTE_REVIEW, { variables: { id } });
    return { upvote, loading };
}
