import React from 'react';
import bemCn from 'bem-cn';
import './style.scss';

const b = bemCn('sniff-select');

const SniffSelect = ({ className, fullHeight, maxMenuHeight = 300, error, Select, ...props }) => {
    return (
        <Select
            blurInputOnSelect={true}
            inputProps={{ readOnly: true }}
            isSearchable={false}
            searchable={false}
            classNamePrefix={b()}
            maxMenuHeight={fullHeight ? 'fit-content' : maxMenuHeight}
            minMenuHeight={fullHeight && 'fit-content'}
            className={`sniff-input ${b.mix(className).mix(error ? 'select-err' : '')}`}
            menuPlacement="auto"
            {...props}
        />
    );
};

export default SniffSelect;
