import React from 'react';
import { APP_DOMAIN } from '../../consts/env.consts';
import { EMAIL_SUPPORT } from '../../consts/link.consts';
import { convertNumToUSD } from '../../helpers/price';
import { COUNTRY_ISO_CODE } from '../../helpers/country';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import { ImageHelper } from '../../classes/image-helper.class';
import SeoReview from '../seo-review';

const SeoLocalBusiness = ({ spot, itemProp, withReviews }) => {
    const isReview = itemProp == 'itemReviewed';
    const priceRange = `${convertNumToUSD(spot?.price * 0.75, 0, 1)}-${convertNumToUSD(spot?.price * 1.5, 0, 1)}`;
    const spotTitle = `${spot?.title || 'Private dog parks'} | Sniffspot`;
    let photos = spot?.allPhotos;
    let imgsCount = spot?.allPhotos?.length > 4 ? 5 : spot?.allPhotos?.length || 0;

    if (spot?.thumbPhoto) {
        photos = [spot.thumbPhoto];
        imgsCount = 1;
    }

    return (
        <div itemScope itemProp={itemProp} itemType="https://schema.org/LocalBusiness" className="d-none">
            <div itemProp="name">{spotTitle}</div>
            {imgsCount > 0 &&
                [...Array(imgsCount)].map((_, i) => (
                    <span key={i} itemProp="image">
                        {ImageHelper.getUrlFromOptions(photos[i], `full-fit-in/${IMG_RESIZES.x768}`)}
                    </span>
                ))}
            <span itemProp="telephone">-</span>
            <div itemProp="paymentAccepted">invoice</div>
            <span itemProp="openingHours" content="Mo,Tu,We,Th,Fr,Sa,Su 0-0" />
            {spot?.latitude && (
                <div itemType="https://schema.org/GeoCoordinates" itemScope itemProp="geo">
                    <meta itemProp="latitude" content={spot.latitude} />
                    {spot?.longitude && <meta itemProp="longitude" content={spot.longitude} />}
                </div>
            )}
            <div itemType="https://schema.org/PostalAddress" itemScope itemProp="address">
                <div>
                    {spot?.city && <span itemProp="addressLocality">{spot.city}</span>}
                    {spot?.state && <span itemProp="addressRegion">{spot.state}</span>}
                    {spot?.zip && <span itemProp="postalCode">{spot.zip}</span>}
                    {spot?.country && <span itemProp="addressCountry">{COUNTRY_ISO_CODE[spot.country]}</span>}
                </div>
            </div>
            <meta itemProp="currenciesAccepted" content="USD" />
            {spot?.price && <meta itemProp="priceRange" content={priceRange} />}
            {spot?.spotUrl && <meta itemProp="url" content={`${APP_DOMAIN}/listings/${spot.spotUrl}`} />}
            {!isReview && spot?.reviewsCount > 0 && spot?.rating >= 1 && (
                <div itemProp="aggregateRating" itemScope itemType="https://schema.org/AggregateRating">
                    <span itemProp="ratingValue">{spot.rating.toFixed(1)}</span>
                    <span itemProp="reviewCount">{spot.reviewsCount}</span>
                </div>
            )}
            {withReviews?.map((review, i) => (
                <SeoReview key={i} {...{ ...review, user: review.providedBy, itemProp: 'review' }} />
            ))}
            <div itemProp="parentOrganization" itemScope itemType="https://schema.org/parentOrganization">
                <span itemProp="name">Sniffspot</span>
                <span itemProp="email">{EMAIL_SUPPORT}</span>
                <span itemProp="location">United States</span>
                <meta itemProp="url" content={APP_DOMAIN} />
                <div itemProp="founder" itemScope itemType="https://schema.org/founder">
                    <span itemProp="name">David Adams</span>
                    <span itemProp="jobTitle">Founder</span>
                    <meta itemProp="url" content="https://www.linkedin.com/in/david-a-85aa1526/" />
                </div>
            </div>
        </div>
    );
};

export default SeoLocalBusiness;
