import React from 'react';
import { useHistory } from 'react-router';
import { RouteFormatter } from '../../routes';
import { SPOT_SRC_MEMBERSHIP } from '../../consts/spot.consts';
import { showPrice } from '../../helpers/price';
import SvgKeyboardRight from 'src/assets/svg/SvgKeyboardRight.svg';
import SvgMembership from 'src/assets/svg/SvgMembership.svg';
import SvgSeoOk from 'src/assets/svg/SvgSeoOk.svg';
import SvgSubscription from 'src/assets/svg/SvgSubscription.svg';

const RenderPrice = ({ spot, SSR, hasSniffpass }) => {
    const history = SSR ? null : useHistory();
    const isForeign = spot?.country !== 'United States';

    const handleLearn = () => {
        if (SSR) {
            window.location = encodeURI(
                RouteFormatter.membershipsDetail({ id: spot.id }, { query: { src: SPOT_SRC_MEMBERSHIP.WEB_DETAIL_PRICING } })
            );
        } else {
            history.push({
                pathname: RouteFormatter.membershipsDetail({ id: spot.id }),
                state: { src: SPOT_SRC_MEMBERSHIP.WEB_DETAIL_PRICING },
            });
        }
    };

    const handleSniffpass = () => {
        if (SSR) {
            window.location = encodeURI(RouteFormatter.subscriptionsNew(null, { query: { src: SPOT_SRC_MEMBERSHIP.WEB_DETAIL_PRICING } }));
        } else {
            history.push({
                pathname: RouteFormatter.subscriptionsNew(),
                state: { src: SPOT_SRC_MEMBERSHIP.WEB_DETAIL_PRICING },
            });
        }
    };

    return (
        <div className="price px-2 px-md-0">
            <div className="snif-m1 snif-semibold mt-3 mb-2">Pricing</div>
            <span className="price-box snif-p">
                <span className="snif-m2 snif-bold">{showPrice(spot.price, 0, isForeign)} </span>
                per dog per hour
            </span>
            <p className="snif-p d-flex align-items-center mt-4">
                <SvgSeoOk className="ok-icon" />
                <span>50% off all dogs after the 1st dog</span>
            </p>
            {spot.minimumLength < 1 && (
                <p className="snif-p d-flex align-items-center mt-3">
                    <SvgSeoOk className="ok-icon" />
                    <span>25% off hourly price for 30 min visit</span>
                </p>
            )}
            {spot.offerMembership && spot.activeMembership && (
                <div className="snif-p d-flex mt-3">
                    <SvgMembership className="ok-icon" />
                    <div>
                        <p>Optional monthly membership available!</p>
                        <span className="d-inline-block c-pointer mt-1" onClick={handleLearn}>
                            <span className="snif-medium text-underline pt-05">Learn more</span>
                            <SvgKeyboardRight className="ms-1" />
                        </span>
                    </div>
                </div>
            )}
            <div className="snif-p d-flex mt-3">
                <SvgSubscription className="ok-icon" />
                <div style={{ width: 'calc(100% - 40px)' }}>
                    <p>
                        {spot.offerSniffpass && (
                            <>
                                Sniffpass holders get discounts on all bookings at all spots by buying monthly credits!
                                <br />
                                This spot offers additional discounts.
                            </>
                        )}
                        {!spot.offerSniffpass && 'Sniffpass holders get discounts on all bookings at all spots by buying monthly credits!'}
                    </p>
                    {!hasSniffpass && (
                        <span className="d-inline-block c-pointer mt-1" onClick={handleSniffpass}>
                            <span className="snif-medium text-underline pt-05">Learn more</span>
                            <SvgKeyboardRight className="ms-1" />
                        </span>
                    )}
                </div>
            </div>
            <div className="mb-3" />
            <hr />
        </div>
    );
};
export default RenderPrice;
