import { gql, useLazyQuery } from '@apollo/client';

const SPOT_REVIEWS = gql`
    query getSpot($id: ID!, $first: Int!, $sortBy: SortBy, $sortOrder: SortOrder) {
        spot(id: $id) {
            id
            reviews(first: $first, sortBy: $sortBy, sortOrder: $sortOrder) {
                id
                stars
                comment
                createdAt
                submittedAt
                providedBy {
                    id
                    avatarAws
                    activeSniffpass
                    nameAndInitial
                    createdAt
                }
                reply {
                    id
                    text
                    createdAt
                }
                photos {
                    id
                    imageAws
                }
                membershipped
                upvotesCount
                upvote
            }
        }
    }
`;

export default function useGetSpotReviews() {
    const [getSpotReviews, { data, loading }] = useLazyQuery(SPOT_REVIEWS);
    return { getSpotReviews, reviews: data && data.spot.reviews, loading };
}
