import React from 'react';
import { connect } from 'react-redux';
import { PAGE_LIMIT } from '../../consts/app.consts';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import useWindowScroll from '../../services/hooks/useWindowScroll';
import ReactPaginate from 'react-paginate';
import SvgChevronLeft from 'src/assets/svg/SvgChevronLeft.svg';
import SvgChevronRight from 'src/assets/svg/SvgChevronRight.svg';
import SvgDoubleChevronLeft from 'src/assets/svg/SvgDoubleChevronLeft.svg';
import SvgDoubleChevronRight from 'src/assets/svg/SvgDoubleChevronRight.svg';
import './style.scss';

const PaginationNext = ({ items, otherSpots = [], metadata, setPage, className }) => {
    const isMobile = useMobileScreen();
    const scrollToStart = useWindowScroll();

    if (!metadata.currentPage) {
        return null;
    }

    const total = metadata.totalCount > 100 ? '100+' : metadata.totalCount;
    const currentPage = metadata.currentPage;
    const totalPages = metadata.totalPages;
    const startI = (currentPage - 1) * PAGE_LIMIT + 1;
    const endI = startI - 1 + PAGE_LIMIT > metadata.totalCount ? metadata.totalCount : startI - 1 + PAGE_LIMIT;

    const handleClick = (data) => {
        setTimeout(() => {
            scrollToStart();
        }, 500);
        setPage(data.selected + 1);
    };

    const onClick = (e, page) => {
        e.preventDefault();
        setTimeout(() => {
            scrollToStart();
        }, 500);
        setPage(page);
    };

    if (items.length + otherSpots.length < 1) {
        return null;
    }

    return (
        <div className={`me-md-1 ${className && className}`}>
            <hr className="d-none d-md-block" />

            {isMobile ? (
                <ul className="my-4 my-md-3 pagination d-flex">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <a className="page-link" href="#" onClick={(e) => onClick(e, 1)}>
                            <SvgDoubleChevronLeft />
                        </a>
                    </li>
                    <li className={`page-item mobile-action-button ${currentPage === 1 ? 'disabled' : ''}`}>
                        <a className="page-link" href="#" onClick={(e) => onClick(e, currentPage - 1)}>
                            <SvgChevronLeft height={16} />
                        </a>
                    </li>
                    <li className="page-item flex-grow-1 text-center p-font-size">
                        <span className="page-link page-number h-100">{`${currentPage}/${totalPages}`}</span>
                    </li>
                    <li className={`page-item mobile-action-button ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <a className="page-link" href="#" onClick={(e) => onClick(e, currentPage + 1)}>
                            <SvgChevronRight height={16} />
                        </a>
                    </li>
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <a className="page-link" href="#" onClick={(e) => onClick(e, totalPages)}>
                            <SvgDoubleChevronRight />
                        </a>
                    </li>
                </ul>
            ) : (
                <div className="d-flex justify-content-between">
                    <div className="page-text">{`Showing ${startI} - ${endI} of ${total} spots`}</div>
                    <nav className="d-flex p-custom">
                        <ReactPaginate
                            pageCount={totalPages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            forcePage={currentPage - 1}
                            onPageChange={(data) => handleClick(data)}
                            nextLabel={
                                <SvgChevronRight
                                    className={`${currentPage === totalPages ? 'text-light' : 'text-primary '}`}
                                    height={16}
                                />
                            }
                            previousLabel={
                                <SvgChevronLeft
                                    className={`${currentPage === 1 ? 'text-light' : 'text-primary '}`}
                                    height={16}
                                />
                            }
                            containerClassName={'pagination p-custom'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link h-100'}
                            previousClassName={'page-item me-1'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item ms-1'}
                            nextLinkClassName={'page-link'}
                            breakClassName={'ms-1'}
                            activeClassName="active"
                        />
                    </nav>
                </div>
            )}
        </div>
    );
};

export default connect(null, (dispatch) => ({
    setMap: () => {
        dispatch({ type: 'SET_MAP_DATA', value: { doNotLoadSpots: true, isFitBounds: false } });
    },
}))(PaginationNext);
