import React, { useEffect } from 'react';
import bemCn from 'bem-cn';
import SvgHostSafety from 'src/assets/svg/SvgHostSafety.svg';
import './style.scss';

const useUserDetailsShort = require('../../services/hooks/useGetUserDetailsShort');
const useGetMyVerifyDetails = require('../../services/hooks/useGetMyVerifyDetails');

const b = bemCn('account-verified-banner');

const AccountVerifiedBanner = ({ addedClass = '', userId, serverSSR }) => {
    const { getUserDetailsShort, userDetails: userData, loading: shortLoading } = serverSSR ? {} : useUserDetailsShort.default();
    const { getMyVerifyDetails, userDetails, loading } = serverSSR ? {} : useGetMyVerifyDetails.default();

    useEffect(() => {
        if (userId) {
            getUserDetailsShort({ variables: { id: userId } });
        } else {
            getMyVerifyDetails();
        }
    }, [userId]);

    if (loading || shortLoading) {
        return '';
    }

    const title = userId ? `${userData?.firstname}'s verified information` : 'Your verified information';
    const isEmailVerified = userData?.emailVerified || userDetails?.emailVerified;
    const isPhoneVerified = userData?.phoneNumberVerified || userDetails?.phoneNumberVerified;
    const isStripeVerified = userData?.stripeVerified || userDetails?.stripeVerified;

    if (!isEmailVerified && !isPhoneVerified && !isStripeVerified) {
        return '';
    }

    return (
        <div className={b.mix(addedClass)}>
            <div className={b('title').mix('snif-p snif-medium')}>{title}</div>
            {isStripeVerified && (
                <div className={b('verify-item')}>
                    <SvgHostSafety />
                    <span>Identity</span>
                </div>
            )}
            {isEmailVerified && (
                <div className={b('verify-item')}>
                    <SvgHostSafety />
                    <span>Email address</span>
                </div>
            )}
            {isPhoneVerified && (
                <div className={b('verify-item')}>
                    <SvgHostSafety />
                    <span>Phone number</span>
                </div>
            )}
        </div>
    );
};

export default AccountVerifiedBanner;
