import { useMutation } from '@apollo/client';
import { TRACK_EVENT_MUTATION } from '../../graphql/event-mutations';
import CookieService from '../../services/cookie.service';

export default function useMutateTrackEvent() {
    const uuid = CookieService.get('session_id');
    const [mutateTrackEvent] = useMutation(TRACK_EVENT_MUTATION);

    const trackEvent = ({ name, properties = {} }) => {
        mutateTrackEvent({
            variables: {
                name,
                uuid,
                properties: JSON.stringify(properties),
            },
        });
    };

    return { trackEvent };
}
