import React from 'react';
import bemCn from 'bem-cn';
import './spot-item.scss';

const b = bemCn('skeleton-spot-item');

const SkeletonSpotItem = ({ className }) => {
    return (
        <div className={b.mix(className)}>
            <div className={b('img')} />
            <div className={b('text')} />
            <div className={b('text')} />
            <div className={b('text')} />
        </div>
    );
};

export default SkeletonSpotItem;
