import React, { useEffect, useState } from 'react';
import mapkitToken from '../../helpers/mapkit-token';
import { showDistanceFromYou } from '../../helpers/spot';
import { SPOT_DETAIL_MAP_ZM, SPOT_DETAIL_MAP_ZM_MBL } from '../../consts/spot.consts';
import SniffAppleMaps from '../sniff-apple-maps/map-ssr';
import SvgAnaliticsBar from 'src/assets/svg/SvgAnaliticsBar.svg';
import SvgPin from 'src/assets/svg/SvgPin.svg';
import './index.scss';

const Description = ({ dscrpt }) => {
    if (!dscrpt) {
        return '';
    }

    return (
        <>
            <h2 className="snif-m1 snif-semibold mt-3 mb-3">Description</h2>
            <div className="row">
                <div className="col-md-12 h4">
                    <div className="dscrp snif-p">{dscrpt}</div>
                </div>
            </div>
            <hr />
        </>
    );
};

const Cleanliness = ({ isMobile, clean }) => {
    const [SvgSize, setSvgSize] = useState(16);

    useEffect(() => {
        if (!isMobile) {
            setSvgSize(24);
        }
    }, []);

    if (clean === null) {
        return '';
    }

    return (
        <>
            <h2 className="snif-m1 snif-semibold mt-3 mb-3">Cleanliness</h2>
            <div className="row">
                <div className="col-md-12 h4">
                    <div className="dscrp snif-p">
                        <SvgAnaliticsBar width={SvgSize} height={SvgSize} className="icon" />
                        <span className="snif-semibold">{clean}% of guests</span>
                        {' said this spot was as clean as expected during their visit.'}
                    </div>
                </div>
            </div>
            <hr />
        </>
    );
};

const Hazards = ({ hazards }) => {
    if (!hazards) {
        return '';
    }

    return (
        <>
            <h2 className="snif-m1 snif-semibold mt-3 mb-3">Hazards</h2>
            <div className="row">
                <div className="col-md-12 h4">
                    <div className="dscrp snif-p">{hazards}</div>
                </div>
            </div>
            <hr />
        </>
    );
};

export default function ListingLocation({
    useStateSSR,
    isMobileSSR,
    latitude,
    longitude,
    dscrpt,
    hazards,
    clean,
    city,
    state,
    distanceFrom,
}) {
    const useStateC = useStateSSR;
    const isMobile = isMobileSSR;

    const [isOpen, setIsOpen] = useStateC(false);

    const numLat = latitude ? parseFloat(latitude) : 0;
    const numLng = longitude ? parseFloat(longitude) : 0;

    return (
        <>
            {latitude && longitude ? (
                <div>
                    <div className="location-head px-2 px-md-0">
                        <div className="snif-m1 snif-semibold mt-3">Location</div>
                        {city && state && (
                            <div className="snif-p d-flex align-items-center my-1">
                                <SvgPin width={20} height={20} />
                                <p style={{ width: 'calc(100% - 20px)', paddingLeft: '8px' }}>
                                    {`${city}, ${state}${showDistanceFromYou(distanceFrom)}`}
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="map-container">
                        <div className="row">
                            <div className="col-md-12 map">
                                <SniffAppleMaps
                                    center={{ latitude: numLat, longitude: numLng }}
                                    cameraDistance={isMobile ? SPOT_DETAIL_MAP_ZM_MBL : SPOT_DETAIL_MAP_ZM}
                                    circle
                                    {...{ mapkitToken }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            {dscrpt || hazards || clean ? (
                <div className="location px-2 px-md-0">
                    <Description {...{ dscrpt, isOpen, setIsOpen }} />
                    <Cleanliness {...{ isMobile, clean }} />
                    <Hazards {...{ hazards }} />
                </div>
            ) : null}
        </>
    );
}
