import React from 'react';
import BemCn from 'bem-cn';
import SvgHand from 'src/assets/svg/SvgHand.svg';
import SvgStarEmpty from 'src/assets/svg/SvgStarEmpty.svg';
import SvgTopBadge from 'src/assets/svg/SvgTopBadge.svg';
import './index.scss';

const b = BemCn('spot-top-badge');

const getBadgeData = (top, newSpot, rising) => {
    if (top) {
        return {
            text: 'Top spot',
            icon: <SvgTopBadge />,
            class: 'top',
        };
    } else if (rising) {
        return {
            text: 'Rising star',
            icon: <SvgStarEmpty />,
            class: 'rising',
        };
    } else if (newSpot) {
        return {
            text: 'New',
            icon: <SvgHand />,
            class: 'new',
        };
    }
};

const SpotTopBadge = ({ spot }) => {
    const canShow = spot.top || spot.new || spot.risingStar;

    if (!canShow) {
        return '';
    }

    const data = getBadgeData(spot.top, spot.new, spot.risingStar);

    return (
        <div className={b.mix(data.class)}>
            {data.icon}
            <span>{data.text}</span>
        </div>
    );
};

export default SpotTopBadge;
