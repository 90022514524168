import React from 'react';
import { RESERVATION_TYPE } from '../../consts/reservation.consts';
import { showPrice } from '../../helpers/price';
import SniffButton from '../sniff-button/index.jsx';
import SvgStarSolid from 'src/assets/svg/SvgStarSolid.svg';
import './index.scss';

const ReservationPanel = ({ commentsNmb, rating, price, title, wait, live, handleBook }) => {
    if (wait) {
        return '';
    }

    return (
        <div className="fixed-bottom d-block d-md-none" style={{ position: 'fixed', bottom: 0, left: 0, zIndex: 109 }}>
            <div className="row panel-wrapper">
                <div className="panel">
                    <div className="left">
                        <h3 className="spot-name snif-m1">{title}</h3>
                        <span className="price-mobile snif-s2 snif-medium">
                            <span className="price snif-m2 snif-semibold">{showPrice(price)} </span>
                            per dog per hour
                        </span>
                        <div className="raiting-row">
                            {rating ? (
                                <>
                                    <SvgStarSolid className="star-icon" />
                                    <div className="ratings-count">{rating.toPrecision(2)}</div>
                                    <div className="comments-count">{`(${commentsNmb})`}</div>
                                </>
                            ) : (
                                <span className="new">New!</span>
                            )}
                        </div>
                    </div>
                    <div className="right">
                        <span className="price snif-s2 snif-medium">{showPrice(price)} per dog per hour</span>
                        {live ? (
                            <SniffButton size="lg" className="reservation-button" onClick={() => handleBook(RESERVATION_TYPE.BOOK)}>
                                Book now
                            </SniffButton>
                        ) : (
                            <SniffButton size="lg" className="reservation-button" disabled>
                                Not available
                            </SniffButton>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReservationPanel;
