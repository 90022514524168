import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import { ImageHelper } from '../../classes/image-helper.class';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import { lengthToString } from '../../helpers/time';
import { BOOKING_POLICIES } from './rules.const';
import { HOST_PRESENCE_PREFERENCE } from '../../consts/spot.consts';
import { GENERAL_SNIFFSPOT_RULES } from '../../consts/rules.consts';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import MobileSubPage from '../mobile-sub-page';
import ModalMobile from '../modal-mobile';
import PictureSet from '../picture-set';
import SvgCalendarClock from 'src/assets/svg/SvgCalendarClock.svg';
import SvgChevronDown from 'src/assets/svg/SvgChevronDown.svg';
import SvgChevronRight from 'src/assets/svg/SvgChevronRight.svg';
import SvgChevronUp from 'src/assets/svg/SvgChevronUp.svg';
import SvgCircleCheck from 'src/assets/svg/SvgCircleCheck.svg';
import SvgClock from 'src/assets/svg/SvgClock.svg';
import SvgHandshake from 'src/assets/svg/SvgHandshake.svg';
import SvgPaw from 'src/assets/svg/SvgPaw.svg';
import './index.scss';

const HostPresence = ({ hostPresencePreference }) =>
    hostPresencePreference && (
        <div className="d-flex">
            <SvgHandshake className="rule-icon" />
            <div className="rule-list-item">
                <div>Is the host present?</div>
                <span>{HOST_PRESENCE_PREFERENCE[hostPresencePreference]}</span>
            </div>
        </div>
    );

const HostRules = ({ rules, avatar, useStateSSR, isOpen, setIsOpen }) => {
    if (!rules) {
        return '';
    }

    const isLong = rules.length > 240;
    const firstDesc = rules.substring(0, 240);
    const avatarUrl = avatar ? ImageHelper.getUrlFromOptions(avatar, `full-fit-in/${IMG_RESIZES.x64}`) : '';

    return (
        <div className="d-flex">
            <PictureSet
                className="rule-icon avatar"
                imgs={avatarUrl}
                SSR={!!useStateSSR}
                useState={useStateSSR}
                isAvatar
            />
            <div className="rule-list-item break-word text-pre">
                <div>Additional rules</div>
                {useStateSSR ? (
                    <p>{rules}</p>
                ) : (
                    <span>
                        {isLong ? (isOpen ? '' : firstDesc.padEnd(243, '.')) : rules}
                        {isLong && (
                            <>
                                <Collapse isOpen={isOpen}>{rules}</Collapse>
                                <div
                                    onClick={() => setIsOpen(!isOpen)}
                                    className="read-more snif-semibold text-dark mt-2 mb-2"
                                >
                                    {isOpen ? 'Show less details' : 'Show more details'}
                                    {isOpen ? (
                                        <SvgChevronUp width={14} className="ms-1 text-dark" />
                                    ) : (
                                        <SvgChevronDown width={14} className="ms-1 text-dark" />
                                    )}
                                </div>
                            </>
                        )}
                    </span>
                )}
            </div>
        </div>
    );
};

const MinimumVisitLength = ({ minimumLength }) =>
    minimumLength && (
        <div className="d-flex">
            <SvgClock className="rule-icon" />
            <div className="rule-list-item">
                <div>Minimum visit length</div>
                <span>{lengthToString(minimumLength)}</span>
            </div>
        </div>
    );

const ReservationDelayTime = ({ reservationDelayTime }) =>
    reservationDelayTime > 0 && (
        <div className="d-flex">
            <SvgCalendarClock className="rule-icon" />
            <div className="rule-list-item">
                <div>Required advanced notice for visits</div>
                <span>{reservationDelayTime === 1 ? '1 hour' : `${reservationDelayTime} hours`}</span>
            </div>
        </div>
    );

const MaxDogsAllowed = ({ maximumDogsAllowed }) =>
    maximumDogsAllowed && (
        <div className="d-flex">
            <SvgPaw className="rule-icon" />
            <div className="rule-list-item">
                <div>Max dogs per booking</div>
                <span>{maximumDogsAllowed}</span>
            </div>
        </div>
    );

const BookingPolicies = () => (
    <table className="rule-list">
        <tbody>
            {BOOKING_POLICIES.map((v, i) => (
                <tr key={i}>
                    <td>
                        <SvgCircleCheck className="general-rule-icon" />
                    </td>
                    <td className="rule-list-item">{v}</td>
                </tr>
            ))}
        </tbody>
    </table>
);

const GeneralRuleList = () => (
    <table className="rule-list">
        <tbody>
            {GENERAL_SNIFFSPOT_RULES.map(({ text }, i) => (
                <tr key={i}>
                    <td>
                        <SvgCircleCheck className="general-rule-icon" />
                    </td>
                    <td className="rule-list-item general">{text}</td>
                </tr>
            ))}
        </tbody>
    </table>
);

const MobileRulesModal = ({ rulesModal, rulesToggle, useEffectSSR, useStateSSR }) => (
    <MobileSubPage
        show={rulesModal}
        title="Booking Policies"
        onBack={rulesToggle}
        mBottom={96}
        {...{ useEffectSSR, useStateSSR }}
    >
        <BookingPolicies />
    </MobileSubPage>
);

const DesktopRulesModal = ({ policiesModal, policiesToggle, rulesModal, rulesToggle }) => (
    <>
        <ModalMobile isOpen={policiesModal} toggle={policiesToggle} title="Booking Policies">
            <BookingPolicies />
        </ModalMobile>
        <ModalMobile isOpen={rulesModal} toggle={rulesToggle} title="Community safety rules">
            <GeneralRuleList />
        </ModalMobile>
    </>
);

export default function SpotRules({
    maximumDogsAllowed,
    breedSizeRestrictions,
    minimumLength,
    hostPresencePreference,
    reservationDelayTime,
    avatar,
    rules,
    isMobileSSR,
    useStateSSR,
    useEffectSSR,
}) {
    const isMobile = useStateSSR ? isMobileSSR : useMobileScreen();
    const [isOpen, setIsOpen] = useState(false);
    const [rulesModal, setRulesModal] = useState(false);
    const [policiesModal, setPoliciesModal] = useState(false);
    const [RulesModal, setRulesModalC] = useState(() => MobileRulesModal);
    const haveData =
        maximumDogsAllowed ||
        breedSizeRestrictions ||
        hostPresencePreference ||
        rules ||
        minimumLength ||
        reservationDelayTime;

    const rulesToggle = () => setRulesModal(!rulesModal);
    const policiesToggle = () => setPoliciesModal(!policiesModal);

    const RulesModalComp = (
        <RulesModal {...{ rulesModal, rulesToggle, policiesModal, policiesToggle, useEffectSSR, useStateSSR }} />
    );

    useEffect(() => {
        if (!isMobile) {
            setRulesModalC(() => DesktopRulesModal);
        }
    }, []);

    return (
        <div className="rules">
            {haveData && (
                <div className="px-2 px-md-0">
                    <h2 className="snif-m1 snif-semibold mb-3 mt-3">Host Rules</h2>
                    <div className="rule-list">
                        <MinimumVisitLength {...{ minimumLength }} />
                        <ReservationDelayTime {...{ reservationDelayTime }} />
                        <MaxDogsAllowed {...{ maximumDogsAllowed }} />
                        <HostPresence {...{ hostPresencePreference }} />
                        <HostRules {...{ rules, avatar, useStateSSR, isOpen, setIsOpen }} />
                    </div>
                    <hr />
                </div>
            )}
            <div className="d-md-none px-2 px-md-0">
                <div className="snif-m1 snif-semibold mb-3 mt-3">Community safety rules</div>
                <GeneralRuleList />
                <hr />
                <div className="book-policy snif-m1 snif-semibold mb-3 mt-3" onClick={rulesToggle}>
                    Booking Policies
                    <SvgChevronRight />
                </div>
                <hr />
            </div>
            <div className="d-none d-md-block">
                <div className="snif-m1 snif-semibold mb-3 mt-3">Community safety rules</div>
                <GeneralRuleList />
                <p className="mt-1">
                    {"In addition, please make sure to read Sniffspot's "}
                    <span className="desktop-rules" onClick={policiesToggle}>
                        booking policies
                        <span className="dotted-underline" />
                    </span>
                </p>
                <hr />
            </div>
            {RulesModalComp}
        </div>
    );
}
