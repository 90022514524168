import { COOKIE_PARAM_NAME } from '../consts/cookies.consts';
import CookieService from '../services/cookie.service';

export const setFirstClick = () => {
    const isWindow = typeof window !== undefined;

    if (!isWindow) {
        return;
    }

    const hasFirstClick = CookieService.get(COOKIE_PARAM_NAME.SNIFF_FIRST_CLICK);

    if (hasFirstClick) {
        return;
    }

    const firstClick = window.document.referrer || window.location.href;
    CookieService.set(COOKIE_PARAM_NAME.SNIFF_FIRST_CLICK, firstClick);
};
