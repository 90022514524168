import { gql, useLazyQuery } from '@apollo/client';

const QUERY_AVAILABLE_TIMES = gql`
    query availableTimes(
        $spotId: ID!
        $reservationId: ID
        $date: String
        $time: Float
        $length: Float
        $quantity: Int
        $checkout: Boolean
    ) {
        availableTimes(
            spotId: $spotId
            reservationId: $reservationId
            date: $date
            length: $length
            time: $time
            quantity: $quantity
            checkout: $checkout
        ) {
            spotId
            startDate
            startTime
            unavailableDates
            times
            timesAll
            timesForMembers
            lengths
            length
            quantity
        }
    }
`;

export default function useGetAvailableTimes() {
    const [getAvailableTimes, { data, loading }] = useLazyQuery(QUERY_AVAILABLE_TIMES);
    return { getAvailableTimes, data, loading };
}
