export const EVENTS_NAME = {
    add_a_spot_view: 'add_a_spot_view',
    booking_confirmation_view: 'booking_confirmation_view',
    checkout_payment_view: 'checkout_payment_view',
    checkout_profile_view: 'checkout_profile_view',
    checkout_rules_view: 'checkout_rules_view',
    checkout_view: 'checkout_view',
    edit_spot_view: 'edit_spot_view',
    guest_calendar_view: 'guest_calendar_view',
    guest_manage_membership_view: 'guest_manage_membership_view',
    guest_memberships_view: 'guest_memberships_view',
    guest_message_list_view: 'guest_message_list_view',
    guest_message_thread_view: 'guest_message_thread_view',
    home_view: 'home_view',
    host_account_view: 'host_account_view',
    host_calendar_view: 'host_calendar_view',
    host_landing_page_view: 'host_landing_page_view',
    host_manage_memberships_view: 'host_manage_memberships_view',
    host_memberships_view: 'host_memberships_view',
    host_message_list_view: 'host_message_list_view',
    host_message_thread_view: 'host_message_thread_view',
    host_review_list_view: 'host_review_list_view',
    membership_signup_view: 'membership_signup_view',
    publish_spot_click: 'publish_spot_click',
    reservation_detail_view: 'reservation_detail_view',
    reservation_list_view: 'reservation_list_view',
    search_location_success: 'search_location_success',
    search_view: 'search_view',
    signup_page_view: 'signup_page_view',
    spot_detail_view: 'spot_detail_view',
    visit_cancel_view: 'visit_cancel_view',
    visit_detail_view: 'visit_detail_view',
    visit_edit_view: 'visit_edit_view',
    visit_list_view: 'visit_list_view',
};
