import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AWS_STATIC_ASSET_HOST } from '../../../../consts/env.consts';
import { BrowserRouter } from 'react-router-dom';
import Select from 'react-select';
import '../../../../../styles.scss';
import { setFirstClick } from '../../../../helpers/firstClick';
import SpotDetailSSR from '../../../../pages/spot-view-page/spot-detail-ssr';
import { useLazyGetIntentCal, useUpdateIntent } from '../../hooks/useBooking';
import { useGetFavorites, useMutateFavorites } from '../../hooks/useFavorites';
import useGetAvailableTimes from '../../hooks/useGetAvailableTimes';
import useGetSpotReviews from '../../hooks/useGetSpotReviews';
import useGetShareLink from '../../hooks/useGetShareLink';
import useMobileScreen from '../../hooks/useMobileScreen';
import useOnScreen from '../../hooks/useOnScreen';
import usePrevious from '../../hooks/usePrevious';
import { useAddReply, useDeleteReply, useGetReviews, useHandleUpvote } from '../../hooks/useReviews';
import '../../../../helpers/firebase';

// eslint-disable-next-line no-undef
__webpack_public_path__ = `${AWS_STATIC_ASSET_HOST}/packs/`;

const client = new ApolloClient({
    uri: '/graphql',
    cache: new InMemoryCache(),
});

const SpotDetail = (props) => {
    const isMobile = useMobileScreen();

    useEffect(() => {
        setFirstClick();
    }, []);

    return (
        <ApolloProvider client={client}>
            <BrowserRouter>
                <SpotDetailSSR
                    {...{
                        ...props,
                        isMobile,
                        useEffect,
                        useState,
                        useRef,
                        useMemo,
                        useCallback,
                        usePrevious,
                        useGetAvailableTimes,
                        useLazyGetIntentCal,
                        useUpdateIntent,
                        useGetFavorites,
                        useMutateFavorites,
                        useGetShareLink,
                        useAddReply,
                        useDeleteReply,
                        useHandleUpvote,
                        useGetSpotReviews,
                        useOnScreen,
                        useGetReviews,
                        Select,
                    }}
                />
            </BrowserRouter>
        </ApolloProvider>
    );
};

export default SpotDetail;
