import { gql, useLazyQuery, useMutation } from '@apollo/client';

const QUERY_GET_INTENT = gql`
    query intent($spotId: ID!) {
        intent(spotId: $spotId) {
            id
            date
            time
            length
            quantity
            note
            discountCode
            spotId
            extraIds
        }
    }
`;

const UPDATE_INTENT_MUTATION = gql`
    mutation updateIntent(
        $spotId: ID!
        $date: String
        $time: Float
        $length: Float
        $quantity: Int
        $discountCode: String
        $note: String
        $extraIds: [ID]
        $sessionUuid: String
    ) {
        updateIntent(
            spotId: $spotId
            date: $date
            length: $length
            quantity: $quantity
            discountCode: $discountCode
            note: $note
            time: $time
            extraIds: $extraIds
            sessionUuid: $sessionUuid
        ) {
            date
            time
            length
            quantity
            note
            discountCode
            spotId
            extraIds
        }
    }
`;

export function useLazyGetIntentCal(onCompletedFunc) {
    const [getIntent, { data, loading, called }] = useLazyQuery(QUERY_GET_INTENT, {
        fetchPolicy: 'cache-and-network',
        onCompleted: onCompletedFunc,
        errorPolicy: 'ignore',
    });
    return { getIntent, intent: data?.intent, loading, called };
}

export function useUpdateIntent() {
    const [updateIntent, { loading, data }] = useMutation(UPDATE_INTENT_MUTATION);
    return { updateIntent, loading, data: data?.updateIntent };
}
