import React from 'react';
import SeoLocalBusiness from '../seo-local-business';
import moment from 'moment-timezone';

const SeoReview = ({ comment, spot, stars, submittedAt, user, itemProp, shelter }) => {
    const date = moment(submittedAt).tz('America/New_York').format('YYYY-MM-DD');

    return (
        <div itemProp={itemProp || undefined} itemScope itemType="https://schema.org/Review" className="d-none">
            {spot && <SeoLocalBusiness spot={spot} itemProp="itemReviewed" />}
            {stars && (
                <span itemProp="reviewRating" itemScope itemType="https://schema.org/Rating">
                    <meta itemProp="worstRating" content="1" />
                    <span itemProp="ratingValue">{stars}</span>
                    <span itemProp="bestRating">5</span>
                </span>
            )}
            {submittedAt && <meta itemProp="datePublished" content={date} />}
            <span itemProp="reviewBody">{comment}</span>
            <span itemProp="author" itemScope itemType="https://schema.org/Person">
                <span itemProp="name">{user?.nameAndInitial}</span>
            </span>
            {!shelter && (
                <div itemProp="publisher" itemScope itemType="https://schema.org/Organization">
                    <meta itemProp="name" content="Sniffspot" />
                </div>
            )}
        </div>
    );
};

export default SeoReview;
