import React, { useEffect, useState } from 'react';
import bemCn from 'bem-cn';
import PropTypes from 'prop-types';
import SvgChevronLeft from 'src/assets/svg/SvgChevronLeft.svg';
import './style.scss';

const b = bemCn('mobile-sub-page');

const MobileSubPage = ({
    show = false,
    className = '',
    title = '',
    mBottom = 0,
    onBack,
    useEffectSSR,
    useStateSSR,
    children,
    addedStyle = {},
}) => {
    const useStateC = useStateSSR || useState;
    const useEffectC = useEffectSSR || useEffect;

    const [divH, setDivH] = useStateC(0);

    useEffectC(() => {
        const htmlEle = document.documentElement;
        setDivH(window.innerHeight + 'px');
        const setInnHeight = () => setDivH(window.innerHeight + 'px');
        window.addEventListener('resize', setInnHeight);
        return () => {
            htmlEle.classList.remove('root-html-focused');
            window.removeEventListener('resize', setInnHeight);
        };
    }, []);

    useEffectC(() => {
        const htmlEle = document.documentElement;
        if (show) {
            htmlEle.classList.add('root-html-focused');
        } else {
            htmlEle.classList.remove('root-html-focused');
        }
        return () => htmlEle.classList.remove('root-html-focused');
    }, [show]);

    return (
        <div className={b.mix(`${show ? 'show' : 'hide'} ${className}`)} style={{ height: divH, ...addedStyle }}>
            <div className={b('sub-header').mix('snif-s1 snif-medium')}>
                <span className="back-ico" onClick={onBack}>
                    <SvgChevronLeft />
                </span>
                <span className="sub-title">{title}</span>
            </div>
            <div className={b('sub-content')} style={mBottom ? { paddingBottom: `${mBottom}px` } : {}}>
                {children}
            </div>
        </div>
    );
};

MobileSubPage.propTypes = {
    show: PropTypes.bool,
    className: PropTypes.string,
    title: PropTypes.string,
    onBack: PropTypes.func,
    children: PropTypes.element,
};

export default MobileSubPage;
